import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class ProductMediaSlider extends HTMLElement {
    constructor() {
      super();
      this.slider = new Splide(this, {
        type       : 'fade',
        interval   : 1000,
        speed      : 2000,
        pagination : false,
        arrows     : true,
  //    perPage    : 1,
        direction  : 'ltr',
        wheel      : true,
        releaseWheel : true,
        mediaQuery : 'max',
        wheelSleep : 500,
        wheelMinThreshold : 20,
        arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        heightRatio: 1.285,
        classes    : {
            arrows: 'splide__arrows product-media-arrows splide__arrows--ttb',
            arrow : 'splide__arrow product-media-arrow opacity-1',
            prev  : 'splide__arrow--prev',
            next  : 'splide__arrow--next',
        },
        breakpoints: {
          1024: {
            direction : 'ltr',
            arrows : false,
            wheel : false,
            releaseWheel : false,
          },
        }
      })
    }
  
    connectedCallback() {
     this.slider.mount();
    }
  
}
  
  customElements.define('product-media', ProductMediaSlider);
  